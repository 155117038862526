<template>
	<div class="label-info">
		<div v-if="desktopImg" class="label-info-list mr-3">
			<img :src="desktopImg" alt="desktop banner">
			<span class="typo-helper-text color-black-25">
				Desktop
			</span>
		</div>
		<div v-if="mobileImg" class="label-info-detail mr-4">
			<img :src="mobileImg" alt="mobile banner">
			<span class="typo-helper-text color-black-25">
				Mobile
			</span>
		</div>
		<p class="typo-body-2 color-black-45">
			<slot></slot>
		</p>
	</div>
</template>

<script>
export default {
	name: 'BannerInfo',

	props: {
		desktopImg: {
			type: String,
			default: '',
		},
		mobileImg: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-info {
		display: flex;
		align-items: center;

		// .label-info-list
		// .label-info-detail
		&-list,
		&-detail {
			text-align: center;
		}
	}
</style>
