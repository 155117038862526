<template>
	<div>
		<CRow class="mt-5">
			<CCol lg="12">
				<h4 class="mb-4">
					<slot name="title">
					</slot>
				</h4>
				<slot name="description"></slot>
			</CCol>
		</CRow>

		<BannerInfo :desktop-img="desktopImg" :mobile-img="mobileImg">
			<slot name="detail">
				Banners will be displayed on the highlight banner widget as
				shown. Using the same size for desktop and mobile.
			</slot>
		</BannerInfo>

		<slot></slot>

		<CRow v-if="hasTitle">
			<CCol md="6">
				<CInput
					v-model.trim="$v.titleThValue.$model"
					:is-valid="!$v.titleThValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.titleTh', {required: requiredTitle? '*':''})"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.titleEnValue.$model"
					:is-valid="!$v.titleEnValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.titleEn', {required: requiredTitle? '*':''})"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasSubTitle">
			<CCol md="6">
				<CInput
					v-model.trim="$v.subTitleThValue.$model"
					:is-valid="!$v.subTitleThValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Banner Subtitle TH"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.subTitleEnValue.$model"
					:is-valid="!$v.subTitleEnValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Banner Subtitle EN"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasDescription">
			<CCol md="6">
				<CInput
					v-model.trim="$v.descriptionThValue.$model"
					:is-valid="!$v.descriptionThValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.descriptionTh', {required: isRequiredDescription? '*':''})"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.descriptionEnValue.$model"
					:is-valid="!$v.descriptionEnValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.descriptionEn', {required: isRequiredDescription? '*':''})"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasDisplayPrice" class="display-price-group">
			<CCol md="6">
				<BaseInputNumber
					v-model="$v.displayPriceValue.$model"
					:decimal-scale="2"
					:is-valid="!$v.displayPriceValue.$error"
					:invalid-feedback="$t('global.error.required')"
					allow-null-value
					label="display price"
					append-text="฿"
					text-align="left"
					placeholder="Type display price"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasButtonText">
			<CCol md="6">
				<CInput
					v-model.trim="$v.buttonTextThValue.$model"
					:is-valid="!$v.buttonTextThValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.buttonTextTh', {required: isRequiredButtonText? '*':''})"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.buttonTextEnValue.$model"
					:is-valid="!$v.buttonTextEnValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="$t('components.contentWrapper.label.buttonTextEn', {required: isRequiredButtonText? '*':''})"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasBannerLink">
			<CCol md="12">
				<CInput
					v-model.trim="$v.bannerLinkValue.$model"
					:is-valid="!$v.bannerLinkValue.$error && null"
					:invalid-feedback="bannerLinkErrorMessage"
					:description="bannerLinkHelperText"
					:label="$t('components.contentWrapper.label.linkText', {required: requiredLink? '*':''})"
				/>
			</CCol>
		</CRow>

		<CRow v-if="hasBadgeLabel" class="pl-3 pr-0">
			<ProductEditLabelList
				class="w-100"
				:type="PRODUCT_LABEL_TYPES.badge"
				:list="badgeLabels"
				:disabled="disableAddBadgeLabelButton"
				title="Badge label"
				description="Badge label will display in banner section"
				@onAdd="openProductBadgeModal(PRODUCT_LABEL_TYPES.badge)"
				@onDelete="handleDeleteLabel"
			/>
		</CRow>

		<hr>
		<ModalProductLabel
			ref="modal-badge-label"
			@onSubmit="handleSubmitLabel"
		/>
	</div>
</template>

<script>
import BannerInfo from '@/components/BannerInfo.vue';
import ModalProductLabel from '@/components/ModalProductLabel.vue';
import ProductEditLabelList from '@/components/ProductEditLabelList.vue';
import { requiredIf, url } from 'vuelidate/lib/validators';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'BannerContentWrapper',

	components: {
		BannerInfo,
		ModalProductLabel,
		ProductEditLabelList,
	},

	validations() {
		return {
			bannerLinkValue: {
				// user must selected any banner image(s) or button text
				// and the value on banner link input must not be empty
				required: requiredIf((data) => data.requiredLink && (data.bannerLink || data.hasButtonText)),
				// check invalid url format
				url,
			},

			titleThValue: {
				// user must selected any banner image(s)
				// and the value on banner additional input must not be empty
				required: requiredIf((data) => data.requiredTitle && data.hasTitle),
			},

			titleEnValue: {
				// user must selected any banner image(s)
				// and the value on banner additional input must not be empty
				required: requiredIf((data) => data.requiredTitle && data.hasTitle),
			},

			subTitleThValue: {
				required: requiredIf((data) => data.isRequiredSubTitle && data.hasSubTitle),
			},
			subTitleEnValue: {
				required: requiredIf((data) => data.isRequiredSubTitle && data.hasSubTitle),
			},

			descriptionThValue: {
				required: requiredIf((data) => data.isRequiredDescription && data.hasDescription),
			},
			descriptionEnValue: {
				required: requiredIf((data) => data.isRequiredDescription && data.hasDescription),
			},
			buttonTextThValue: {
				required: requiredIf((data) => data.isRequiredButtonText && data.hasButtonText),
			},
			buttonTextEnValue: {
				required: requiredIf((data) => data.isRequiredButtonText && data.hasButtonText),
			},
			displayPriceValue: {
				required: requiredIf((data) => data.isRequiredDisplayPrice && data.hasDisplayPrice),
			},
		};
	},

	props: {
		desktopImg: {
			type: String,
			default: '',
		},
		mobileImg: {
			type: String,
			default: '',
		},
		requiredLink: {
			type: Boolean,
			default: false,
		},
		bannerLink: {
			type: String,
			default: '',
		},
		hasBannerLink: {
			type: Boolean,
			default: true,
		},
		requiredTitle: {
			type: Boolean,
			default: false,
		},
		titleTh: {
			type: String,
			default: '',
		},
		titleEn: {
			type: String,
			default: '',
		},
		hasTitle: {
			type: Boolean,
			default: false,
		},

		// Banner subtitle
		hasSubTitle: {
			type: Boolean,
			default: false,
		},
		isRequiredSubTitle: {
			type: Boolean,
			default: false,
		},
		subTitleTh: {
			type: String,
			default: '',
		},
		subTitleEn: {
			type: String,
			default: '',
		},

		// Banner description
		hasDescription: {
			type: Boolean,
			default: false,
		},
		isRequiredDescription: {
			type: Boolean,
			default: false,
		},
		descriptionTh: {
			type: String,
			default: '',
		},
		descriptionEn: {
			type: String,
			default: '',
		},

		// Banner button text
		hasButtonText: {
			type: Boolean,
			default: false,
		},
		isRequiredButtonText: {
			type: Boolean,
			default: false,
		},
		buttonTextTh: {
			type: String,
			default: '',
		},
		buttonTextEn: {
			type: String,
			default: '',
		},

		// Banner display price
		hasDisplayPrice: {
			type: Boolean,
			default: false,
		},
		isRequiredDisplayPrice: {
			type: Boolean,
			default: false,
		},
		displayPrice: {
			type: [String, Number],
			default: null,
		},

		// Badge Label
		hasBadgeLabel: {
			type: Boolean,
			default: false,
		},
		badgeLabels: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},

	computed: {
		bannerLinkValue: {
			get() {
				return this.bannerLink;
			},
			set(value) {
				this.$emit('update:bannerLink', value);
			},
		},

		// Banner title
		titleThValue: {
			get() {
				return this.titleTh;
			},
			set(value) {
				this.$emit('update:titleTh', value);
			},
		},
		titleEnValue: {
			get() {
				return this.titleEn;
			},
			set(value) {
				this.$emit('update:titleEn', value);
			},
		},

		// Banner subtitle
		subTitleThValue: {
			get() {
				return this.subTitleTh;
			},
			set(value) {
				this.$emit('update:subTitleTh', value);
			},
		},
		subTitleEnValue: {
			get() {
				return this.subTitleEn;
			},
			set(value) {
				this.$emit('update:subTitleEn', value);
			},
		},

		// Banner description
		descriptionThValue: {
			get() {
				return this.descriptionTh;
			},
			set(value) {
				this.$emit('update:descriptionTh', value);
			},
		},
		descriptionEnValue: {
			get() {
				return this.descriptionEn;
			},
			set(value) {
				this.$emit('update:descriptionEn', value);
			},
		},

		// Banner text button
		buttonTextThValue: {
			get() {
				return this.buttonTextTh;
			},
			set(value) {
				this.$emit('update:buttonTextTh', value);
			},
		},
		buttonTextEnValue: {
			get() {
				return this.buttonTextEn;
			},
			set(value) {
				this.$emit('update:buttonTextEn', value);
			},
		},

		// Banner display price
		displayPriceValue: {
			get() {
				return this.displayPrice;
			},
			set(value) {
				this.$emit('update:displayPrice', value);
			},
		},

		bannerLinkHelperText() {
			return this.bannerLinkErrorMessage === null
				? this.$t('page.banner.create.linkBannerHelperText')
				: null;
		},

		bannerLinkErrorMessage() {
			if (!this.$v.bannerLinkValue.url) {
				return this.$t('page.banner.create.linkBannerInvalidFormat');
			} else if (!this.$v.bannerLinkValue.required) {
				return this.$t('global.error.required');
			}
			return null;
		},

		// label
		disableAddBadgeLabelButton() {
			return this.badgeLabels && this.badgeLabels.length > 0;
		},
	},
	methods: {
		openProductBadgeModal(type) {
			this.$refs['modal-badge-label'].open(type);
		},

		// Label
		handleSubmitLabel({ labels }) {
			// Filter existing label out
			const newLabels = labels.filter((item) => {
				return !this.badgeLabels.some((label) => label.id === item.id);
			});

			const allLabels = [
				...this.badgeLabels,
				...newLabels,
			];

			this.$emit('update:badgeLabels', allLabels);
		},
		handleDeleteLabel({ id }) {
			const allLabels = this.badgeLabels.filter((label) => label.id !== id);
			this.$emit('update:badgeLabels', allLabels);
		},
	},
};
</script>

<style lang="scss" scoped>
.display-price-group {
	margin-bottom: rem(20);
}
</style>
